html,
body {
  box-sizing: border-box;
  overflow-x: hidden;
}

.anim {
  width: 100vw;
  height: 100vh;
  background-color: black;
}

h2 {color: white;
    text-align: center;
  }



#logo { position: absolute;  right: 75px; top: 150px;   text-align: center; z-index: 100;  }

#logo2 { position: absolute;  top: 150px; width: 100%; text-align: center;   }



#uc_icon { position: absolute;  right: 500px; top: 595px; width: 100px; text-align: center;   }

#solana_logo { position: absolute;  right: 825px; top: 600px; width: 150px; text-align: center;   }

#factorylogo { position: absolute; right: 1000px; top: 580px; width: 125px; text-align: center;  }


#phtm_logo { position: absolute; right: 675px; top: 600px; width: 100px; text-align: center; z-index: 100; overflow: hidden; }
